import React, { useState, useEffect } from "react";

import { useKKiaPay } from "kkiapay-react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

//import { useNavigate } from "react-router-dom";

//import { createReadStream } from "fs";

//import FormData from "form-data";
//import fs from "fs";

function Payer() {
  // const { openKkiapayWidget } = useKKiaPay();
  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66);

  const navigate = useNavigate();

  const { openKkiapayWidget, addKkiapayListener } = useKKiaPay();
  /*
  const handleSubmit = async () => {
    const data = new FormData();
    setLoading(true);

    const fields = [
      { name: "nomVendeur", key: "name" },
      { name: "nomBoutique", key: "type" },
      { name: "type", key: "type" },
      { name: "numeroWaveVendeur", key: "venture_address" },
      { name: "numero", key: "registrationPhone" },
      { name: "email", key: "registrationEmail" },
      { name: "localisation", key: "localisation" },
      { name: "numeroCompteBancaireVendeur", key: "bank_account" },

      { name: "montant", key: "bank_account" },
    ];

    fields.forEach((field) => {
      const value = localStorage.getItem(field.key);
      if (value) {
        data.append(field.name, value);
        
      }
    });

    // Fonction pour reconvertir Base64 en Blob et l'ajouter au FormData
    const appendFileFromLocalStorage = (base64Key, nameKey, fieldName) => {
      const base64Data = localStorage.getItem(base64Key);
      const fileName = localStorage.getItem(nameKey);

      if (base64Data && fileName) {
        const byteString = atob(base64Data.split(",")[1]); // Décoder Base64
        const mimeType = base64Data.split(",")[0].match(/:(.*?);/)[1]; // Extraire le type MIME

        // Convertir en tableau d’octets
        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: mimeType });
        data.append(fieldName, blob, fileName);
      }
    };

    // Ajouter les fichiers au FormData
    appendFileFromLocalStorage(
      "photoVendeur",
      "photoVendeurName",
      "photoVendeur"
    );
    appendFileFromLocalStorage(
      "photoPieceIdentiteVendeur",
      "photoPieceIdentiteVendeurName",
      "photoPieceIdentiteVendeur"
    );

    try {
      const response = await axios.post(
        "http://localhost:3000/ajouter-vendeur",
        data
      );
      setLoading(false);
      navigate("/payer");
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      setLoading(false);
      console.error("Erreur lors de la soumission du formulaire:", error);
    }
  };

  */

  async function ajouterVendeur() {
    // Récupérer les données Base64 depuis localStorage
    const id = localStorage.getItem("id");
    const base = process.env.REACT_APP_BASE_URL;
    const apiKey = process.env.REACT_APP_API_KEY;

    try {
      const response = await axios.get(`${base}vendeurs`, {
        params: {
          nouveauSolde: 108,
          idVendeur: id,
        },
      });
      console.log("Réponse du serveur :", response.data);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du solde :", error.message);
    }
  }

  useEffect(() => {
    addKkiapayListener("success", () => {
      // navigate("/recu");
      //     handleSubmit();
    });
    //  addKkiapayListener("failed", failureHandler);
  }, [addKkiapayListener]);

  function open() {
    openKkiapayWidget({
      amount: 100,
      api_key: "6c0c43d41afc1c64c1457d08ce5c7c9e18732d44",
      sandbox: false,
      // email: "randomgail@gmail.com",
      //  phone: "97000000",
    });
  }

  return (
    <section className="lg:px-40 md:px-32 sm:px-10 px-5 min-h-[200px] lg:py-20 py-10">
      <header className="font-extrabold text-2xl text-center mb-10">
        <span className="text-black">DJONAN2</span>
        <span className="text-jaune">PAY </span>
      </header>

      <article className="flex flex-row space-x-4 mx-5 md:mx-64">
        <button className="bg-jaune w-1/3 p-[2px] block font-extrabold text-white text-lg"></button>
        <button className="bg-jaune w-1/3 p-[2px] block font-extrabold text-white text-lg"></button>
        <button className="bg-vert w-1/3 p-[2px] block font-extrabold text-white text-lg"></button>
      </article>

      <article className="lg:w-7/12 mx-auto mt-10">
        <form method="POST" encType="multipart/form-data">
          <div className="my-4 bg-yellow-100 p-4 rounded-xl text-center">
            <p className="font-bold text-lg">
              Pour finaliser votre inscription, vous devez payer une caution de
              10.000 F CFA.
            </p>
            <p className="text-sm mt-2">
              Vous pouvez decider de retirer ce montanta tout moment dans votre
              espace vendeur
            </p>
          </div>

          <div className="mt-10 grid grid-cols-2 lg:gap-10 gap-5">
            <button
              onClick={() => {
                window.history.back();
              }}
              type="button"
              className="bg-jaune text-white font-bold w-full rounded-xl p-3"
            >
              Retour
            </button>
            <button
              type="button"
              onClick={() => {
                //open();
                ajouterVendeur();
              }}
              className="bg-vert text-white font-bold w-full rounded-xl p-3"
            >
              Payer
            </button>
          </div>
        </form>
      </article>
    </section>
  );
}

export default Payer;
