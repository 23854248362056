import React, { useState } from "react";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

import { useNavigate } from "react-router-dom";

function GigaDriverForm() {
  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    venture_address: "",
    bank_account: "",
    photoVendeur: null,
    photoPieceIdentiteVendeur: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      // Handle file inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      // Handle text inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    setLoading(true);

    const type = localStorage.getItem("type");

    const savedEmail = localStorage.getItem("registrationEmail");
    const savedPhone = localStorage.getItem("registrationPhone");

    // Append form data
    data.append("nomVendeur", formData.name);
    data.append("nomBoutique", type);

    data.append("type", type);

    data.append("numeroWaveVendeur", formData.venture_address);

    data.append("numero", savedPhone);

    data.append("email", savedEmail);

    data.append("localisation", formData.localisation);
    data.append("numeroCompteBancaireVendeur", formData.bank_account);
    data.append("photoVendeur", formData.photoVendeur);
    data.append(
      "photoPieceIdentiteVendeur",
      formData.photoPieceIdentiteVendeur
    );

    //"http://localhost:3000/ajouter-livreur"
    try {
      const base = process.env.REACT_APP_BASE_URL;
      //   alert(base);
      const response = await axios.post(`${base}ajouter-livreur`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      navigate("/recu");

      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      setLoading(false);
      // navigate("/recu");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <section className="lg:px-40 md:px-32 sm:px-10 px-5 min-h-[200px] lg:py-20 py-10">
      <header className="font-extrabold text-2xl text-center mb-10">
        <span className="text-vert">DJONAN2</span>
        <span className="text-jaune">PAY </span>
      </header>

      <article className="flex flex-row space-x-4 mx-5 md:mx-64">
        <button className="bg-jaune w-1/2 p-[2px] block font-extrabold text-white text-lg"></button>
        <button className="bg-vert w-1/2 p-[2px] block font-extrabold text-white text-lg"></button>
      </article>

      <article className="lg:w-7/12 mx-auto mt-10">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="my-4">
            <label className="font-bold block mb-3 text-sm">Nom complet</label>
            <input
              required
              type="text"
              name="name"
              placeholder="Votre nom et prenoms"
              className="block bg-gray-200 rounded-xl p-3 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="my-4">
            <label className="font-bold block mb-3 text-sm">Localisation</label>
            <input
              required
              type="text"
              name="localisation"
              placeholder="Adresse de l'entreprise"
              className="block bg-gray-200 rounded-xl p-3 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="my-4">
            <label className="font-bold block mb-3 text-sm">
              Numéro Wave de reversement
            </label>
            <input
              required
              type="text"
              name="venture_address"
              placeholder="Numéro Wave"
              className="block bg-gray-200 rounded-xl p-3 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="my-4">
            <label className="font-bold block mb-3 text-sm">
              Numéro de compte bancaire
            </label>
            <input
              required
              type="text"
              name="bank_account"
              placeholder="Numéro de compte bancaire"
              className="block bg-gray-200 rounded-xl p-3 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="my-4">
            <label className="font-bold block mb-3 text-sm">
              Photo du livreur
            </label>
            <input
              type="file"
              required
              name="photoVendeur"
              className="block bg-gray-200 rounded-xl p-3 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="my-4">
            <label className="font-bold block mb-3 text-sm">
              Photo de la pièce d'identité
            </label>
            <input
              type="file"
              required
              name="photoPieceIdentiteVendeur"
              className="block bg-gray-200 rounded-xl p-3 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="mt-10 grid grid-cols-2 lg:gap-10 gap-5">
            <button
              onClick={() => {
                window.history.back();
              }}
              type="button"
              className="bg-jaune text-white font-bold w-full rounded-xl p-3"
            >
              Retour
            </button>
            <button
              type="submit"
              className="bg-vert text-white font-bold w-full rounded-xl p-3  flex flex-row items-center justify-center"
            >
              {loading ? (
                // Barre de progression circulaire en rotation
                <>
                  <div className="w-6 h-6 animate-spin flex justify-center items-center">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "transparent",
                        strokeLinecap: "round",
                      })}
                    />{" "}
                  </div>
                </>
              ) : (
                ""
              )}
              <span className="text-white pl-2">
                {loading ? "  enregistrement" : "continuer"}
              </span>
            </button>
          </div>
        </form>
      </article>
    </section>
  );
}

export default GigaDriverForm;
