import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexVisiteur from "../visiteurs/IndexVisiteur";

import GigaDriverForm from "../visiteurs/etapes/GigaDriverForm";

import Enpreprise from "../visiteurs/etapes/Enpreprise";

import Vendeur from "../visiteurs/etapes/Vendeur";

import Un from "../visiteurs/etapes/Un";

import Payer from "../visiteurs/etapes/Payer";

import Recu from "../visiteurs/etapes/Recu";

const VisiteursRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexVisiteur />} />

      <Route path="/enregistrement" element={<IndexVisiteur />} />
      <Route path="/niveau-un" element={<Un />} />

      <Route path="/niveau-deux" element={<GigaDriverForm />} />

      <Route path="/entreprise" element={<Enpreprise />} />

      <Route path="/vendeur" element={<Vendeur />} />

      <Route path="/payer" element={<Payer />} />

      <Route path="/recu" element={<Recu />} />
    </Routes>
  );
};

export default VisiteursRoutes;
