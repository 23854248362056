import IndexVisiteur from "./visiteurs/IndexVisiteur";
import GigaDriverForm from "./visiteurs/etapes/GigaDriverForm";

//import Navigator from "./composant/Navigator";
import React, { useState } from "react";

import Navigator from "./Navigator";

const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const base = process.env.REACT_APP_BASE_URL;

console.log("API URL:", apiUrl);
console.log("base:", base);

//alert;
//<GigaDriverForm />

export default function App() {
  return <Navigator />;
}
