import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer className="lg:px-20 sm:px-10 px-5 py-10 bg-gray-200 ">
      <article className="flex md:flex-row flex-col items-center lg:justify-between justify-start">
        {/* Bolt with links here */}
        <div className="lg:w-3/12 w-full">
          <ul>
            <li>
              <a href="/" className="">
                <img src="/path-to-your-image/bj.webp" alt="" className="w-6" />
              </a>
            </li>
          </ul>
        </div>
      </article>

      <article className="my-10 flex md:flex-row flex-col items-center justify-between">
        <div className="lg:w-2/12 md:w-3/12 w-full">
          <ul className="flex lg:gap-5 gap-6 text-gray-700 lg:text-xl items-end">
            <li>
              <a href="/">
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-tiktok"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="lg:w-10/12 md:w-9/12 w-full md:mt-0 mt-6">
          <ul className="flex lg:gap-10 gap-7 font-medium text-sm md:justify-end items-center flex-wrap">
            <li>
              <a href="/">
                <i className="fab fa-android"></i>
                <span>Telecharger pour Android</span>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-app-store"></i>
                <span>Telecharger pour iOS</span>
              </a>
            </li>
          </ul>
        </div>
      </article>

      <article className="flex md:flex-row flex-col-reverse lg:gap-10 gap-7 items-center justify-between mt-10">
        {/* Footer copyright & legale pages links */}
        <div className="lg:w-2/12 md:w-3/12 w-full">
          <span className="font-bold text-xs text-gray-700">
            &copy; DJONAN2PAY- {new Date().getFullYear()}
          </span>
        </div>
        <div className="lg:w-10/12 md:w-9/12 w-full md:mt-0">
          <ul className="flex lg:gap-10 gap-5 font-medium text-sm md:justify-end justify-start flex-wrap">
            <li>
              <a href="/">Mentions legales</a>
            </li>
            <li>
              <a href="/">Termes & conditions</a>
            </li>
            <li>
              <a href="/">Politique de confidentialite</a>
            </li>
          </ul>
        </div>
      </article>
    </footer>
  );
};

export default Footer;
