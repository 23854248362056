import React from "react";

const CheckboxGroup = () => {
  return (
    <div className="flex space-x-6 items-center">
      <div className="flex items-center">
        <input
          id="livreur"
          type="checkbox"
          className="h-5 w-5 text-green-600 border-gray-300 rounded focus:ring-green-500"
        />
        <label htmlFor="livreur" className="ml-2 text-gray-700">
          Livreur
        </label>
      </div>
      <div className="flex items-center">
        <input
          id="vendeur"
          type="checkbox"
          className="h-5 w-5 text-yellow-600 border-gray-300 rounded focus:ring-yellow-500"
        />
        <label htmlFor="vendeur" className="ml-2 text-gray-700">
          Vendeur
        </label>
      </div>
    </div>
  );
};

export default CheckboxGroup;
