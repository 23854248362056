import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import VisiteursRoutes from "./routes/VisiteurRoutes";


export default function Navigator() {
  return (
    <BrowserRouter>
     <Routes>

     <Route path="/*" element={<VisiteursRoutes />} />

      </Routes>
    
    </BrowserRouter>
     ) 
     
     
      }