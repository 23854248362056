import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import { useNavigate } from "react-router-dom";

function Recu() {

   const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center h-screen bg-white">
        
      <div className="bg-vert text-blanc p-8 rounded-lg shadow-lg max-w-md text-center mx-1 ">
        <AiOutlineCheckCircle className="text-7xl text-white mx-auto animate-bounce" />
        <h1 className="text-3xl font-bold text-white mb-4">🎉 Demande reçue !</h1>
        <p className="text-lg mb-6 text-white">
          Merci pour votre demande ! <br />
          Vous recevrez une réponse via <strong>WhatsApp ou email</strong> une fois qu'elle aura été examinée.
        </p>
        <button  onClick={()=>{
          navigate('/')

        }}  className="bg-orange hover:bg-softRed text-white py-2 px-4 rounded transition duration-300">
          Retour à l'accueil
        </button>
      </div>
    </div>
  );
}

export default Recu;
