import React from "react";

import logo from "../../assets/bj.webp";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function Nav() {
  return (
    <nav
      className="lg:px-20 sm:px-10 px-5 flex justify-between items-center py-6 fixed top-0 left-0 right-0"
      id="app-nav"
    >
      <article className="md:w-3/12 w-4/12">
        <ul>
          <li>
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 36 36"
              >
                <path
                  fill="#f77f00"
                  d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5z"
                ></path>
                <path fill="#eee" d="M12 5h12v26H12z"></path>
                <path
                  fill="#009e60"
                  d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </article>

      <article className="md:block md:w-9/12 hidden   ">
        <ul
          id="app-menu"
          className="flex lg:gap-5 gap-3 text-white text-sm items-center justify-end"
        >
          <li>
            <a href="#">Accueil</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
          <li>
            <a href="#">Support</a>
          </li>
          <li>
            <a
              href="#"
              className="bg-vert text-white font-medium text-sm w-full rounded-xl p-2 px-10 inline-block"
            >
              Devenir conducteur
            </a>
          </li>
        </ul>
      </article>

      <article
        id="mp-mobile-menu"
        className="md:relative hidden fixed inset-0 bg-white p-5 z-50"
      >
        <div>
          <div className="flex justify-end">
            <button
              id="mp-close-mobile-menu-button"
              className="text-xl border-2 border-[#20273f] inline-block w-10 h-10"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          <header className="my-5">
            <ul>
              <li>
                <a href="#">
                  <img src="images/bj.webp" alt="Logo" className="w-7 w-5" />
                </a>
              </li>
            </ul>
          </header>

          <ul className="flex flex-col gap-5 justify-end">
            <li>
              <a href="#">Accueil</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
            <li>
              <a href="#">Support</a>
            </li>
            <li>
              <a
                href="#"
                className="bg-vert text-white font-medium text-sm w-full rounded-xl p-2 px-10 inline-block"
              >
                Devenir conducteur
              </a>
            </li>
          </ul>
        </div>
      </article>

      <article className="md:hidden w-8/12">
        <ul className="flex justify-end items-center">
          <li>
            <a
              href="#"
              className="font-extrabold text-2xl"
              id="mobile-menu-toggler"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>
      </article>
    </nav>
  );
}
