import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

import {erreur} from "./erreur"

function Un() {
  const [isLivreurChecked, setIsLivreurChecked] = useState(false); // État pour Livreur
  const [isVendeurChecked, setIsVendeurChecked] = useState(false); // État pour Vendeur
  const [isParticulierChecked, setIsParticulierChecked] = useState(false); // État pour Particulier
  const [isEntrepriseChecked, setIsEntrepriseChecked] = useState(false); // État pour Entreprise

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Logique pour obtenir les valeurs des checkboxes
    const selectedRole = isLivreurChecked ? "Livreur" : isVendeurChecked ? "Vendeur" : null;
    const deliveryType = isParticulierChecked ? "Particulier" : isEntrepriseChecked ? "Entreprise" : null;

    console.log("Formulaire soumis");
    console.log("Role sélectionné:", selectedRole);
    if (selectedRole === "Livreur") {
      localStorage.setItem("type",deliveryType);  // Stocker une chaîne de caractères
      console.log("Type de livraison:", deliveryType);
      if(deliveryType=="Entreprise") navigate("/entreprise"); 
      if(deliveryType=="Particulier")   navigate("/niveau-deux");
      if(deliveryType==null)  erreur()
    }
    else if(selectedRole=="Vendeur"){
       navigate("/vendeur");

    }
    else{
          erreur()

    }

    // Redirection vers la page suivante
   // navigate("/niveau-deux");
  };

  return (
    <section className="lg:px-40 md:px-32 sm:px-10 px-5 min-h-[200px] lg:py-20 py-10">
      <header className="font-extrabold text-2xl text-center mb-10">
        <span className="text-vert">DJONAN2</span>
        <span className="text-jaune">PAY</span>
      </header>
      
      <article className="flex flex-row  space-x-4  mx-5">
        <button className="bg-vert w-1/2 p-[2px] block font-extrabold text-white text-lg"></button>
        <button className="bg-jaune w-1/2 p-[2px] block font-extrabold text-white text-lg"></button>
       
      </article>

      <article className="lg:w-7/12 mx-auto mt-10">
        <form onSubmit={handleSubmit} method="POST" encType="multipart/form-data">
          <div className="my-4">
            <label className="font-bold block mb-3 text-sm text-center">
              Vous souhaitez vous inscrire en tant que ?
            </label>
            <div className="flex space-x-6 items-center">
              <div className="flex flex-row space-x-6 mx-auto">
                <div>
                  <input
                    onChange={(e) => {
                      setIsLivreurChecked(e.target.checked);
                      if (e.target.checked) setIsVendeurChecked(false); // Décocher "Vendeur" si "Livreur" est coché
                    }}
                    id="livreur"
                    type="checkbox"
                    checked={isLivreurChecked} // Vérifie l'état de Livreur
                    className="h-5 w-5 text-green-600 border-gray-300 rounded focus:ring-green-500"
                  />
                  <label htmlFor="livreur" className="ml-2 text-gray-700 font-bold">
                    Livreur
                  </label>
                </div>
                <div>
                  <input
                    onChange={(e) => {
                      setIsVendeurChecked(e.target.checked);
                      if (e.target.checked) setIsLivreurChecked(false); // Décocher "Livreur" si "Vendeur" est coché
                    }}
                    id="vendeur"
                    type="checkbox"
                    checked={isVendeurChecked} // Vérifie l'état de Vendeur
                    className="h-5 w-5 text-yellow-600 border-gray-300 rounded focus:ring-yellow-500"
                  />
                  <label htmlFor="vendeur" className="ml-2 text-gray-700 font-bold">
                    Vendeur
                  </label>
                </div>
              </div>
            </div>
          </div>

          {isLivreurChecked && (
            <div className="my-4">
              <label className="font-bold block mb-3 text-sm text-center">
                Je fais mes livraisons en tant que :
              </label>
              <div className="flex space-x-6 items-center">
                <div className="flex flex-row space-x-6 mx-auto">
                  <div>
                    <input
                    /*
                         onChange={(e) => {
                      setIsVendeurChecked(e.target.checked);
                      if (e.target.checked) setIsLivreurChecked(false); // Décocher "Livreur" si "Vendeur" est coché
                    }}*/

                      onChange={(e) => {
                        setIsParticulierChecked(e.target.checked)

                         if (e.target.checked) setIsEntrepriseChecked(false);
                      }}
                      id="particulier"
                      type="checkbox"
                      checked={isParticulierChecked}
                      className="h-5 w-5 text-green-600 border-gray-300 rounded focus:ring-green-500"
                    />
                    <label htmlFor="particulier" className="ml-2 text-gray-700 font-bold">
                      Particulier
                    </label>
                  </div>
                  <div>
                    <input
                      onChange={(e) => {

                        setIsEntrepriseChecked(e.target.checked)

                        if (e.target.checked)  setIsParticulierChecked(false);


                      }}
                      id="entreprise"
                      type="checkbox"
                      checked={isEntrepriseChecked}
                      className="h-5 w-5 text-yellow-600 border-gray-300 rounded focus:ring-yellow-500"
                    />
                    <label htmlFor="entreprise" className="ml-2 text-gray-700 font-bold">
                      Entreprise
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mt-10 grid grid-cols-2 lg:gap-10 gap-5">
            <button  onClick={()=>{ window.history.back(); }}  type="button" className="bg-jaune text-white font-bold w-full rounded-xl p-3">
              Retour
            </button>
            <button
              type="submit"
              className="bg-vert text-white font-bold w-full rounded-xl p-3"
            >
              Continuer
            </button>
          </div>
        </form>
      </article>
    </section>
  );
}

export default Un;
