import Nav from "./nav/Nav";

import Footer from "./Footer";

import MyComponent from "./MyComponent";
export default function IndexVisiteur() {
  return (
    <div>
      <Nav />
      <MyComponent />
      <Footer />
    </div>
  );
}
